import React from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent, isTechDocsAvailable } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';

import {
  EntityKubernetesContent,
  isKubernetesAvailable,
} from '@backstage/plugin-kubernetes';

// argocd
import {
  EntityArgoCDOverviewCard,
  isArgocdAvailable
} from '@roadiehq/backstage-plugin-argo-cd';

// argo workflows
import {
  isArgoWorkflowsAvailable,
} from '@cnoe-io/plugin-argo-workflows';

import { 
  EntityGrafanaDashboardsCard, 
  isOverviewDashboardAvailable,
  isDashboardSelectorAvailable,
  isAlertSelectorAvailable, 
  EntityOverviewDashboardViewer, 
  EntityGrafanaAlertsCard 
} from '@backstage-community/plugin-grafana';

import { 
  ReadmeCard, 
  isReadmeAvailable 
} from '@axis-backstage/plugin-readme';

import { 
  EntityVaultCard,
  isVaultAvailable
 } from '@backstage-community/plugin-vault';

import {
  HarborPage,
  HarborWidget,
  isHarborAvailable,
} from '@digitalist-open-cloud/backstage-plugin-harbor'

import { 
  KyvernoPolicyReportsTable, 
  KyvernoOverviewCard 
} from '@terasky/backstage-plugin-kyverno-policy-reports';

import { Mermaid } from 'backstage-plugin-techdocs-addon-mermaid';



const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <Mermaid config={{ theme: 'forest', themeVariables: { lineColor: '#000000' } }} />
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const registryContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isHarborAvailable}>
      <HarborPage />
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EmptyState
        title="No container registry data available for this entity"
        missing="info"
        description="Ensure you have added the goharbor.io/repository-slug annotation to your application catalog-info.yaml in order to see this data."
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const dashboardContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={e => isOverviewDashboardAvailable(e)}>
      <Grid style={{ height: '100%' }} md={12}>
          <EntityOverviewDashboardViewer />
      </Grid>
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No dashboard available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const readmeContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isReadmeAvailable}>
      <Grid item md={12}>
        <ReadmeCard variant="fullHeight" />
      </Grid>
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No README available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable README for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const ciContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    {/* <EntitySwitch.Case if={e => isArgoWorkflowsAvailable(e)}>
      <Grid item md={6}>
          <EntityArgoWorkflowsOverviewCard />
      </Grid>
      <Grid item md={6}>
          <EntityArgoWorkflowsTemplateOverviewCard />
      </Grid>
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case> */}
    <EntitySwitch.Case>
      <EmptyState
          title="CI isn't available yet"
          missing="info"
          description="We haven't configured the CI Plugin for this instance of backstage yet. We will aim to have this implemented in the near future"
        />
        </EntitySwitch.Case>
  </EntitySwitch>
);

const cdContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={e => Boolean(isArgocdAvailable(e))}>
      <Grid item sm={12}>
        <EntityArgoCDOverviewCard />
      </Grid>
    </EntitySwitch.Case>
    <EntitySwitch.Case>
      <EmptyState
        title="No CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </>
);

const overviewContent = (
  <Grid container spacing={3} alignItems="stretch">
    {entityWarningContent}
    <Grid item md={6}>
      <EntityAboutCard variant="gridItem" />
    </Grid>
    <Grid item md={6} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
    </Grid>
    <Grid item  md={6} xs={6}>
      <EntityLinksCard variant="gridItem" />
    </Grid>
    <EntitySwitch>
      <EntitySwitch.Case if={isKubernetesAvailable}>
        <Grid item md={6}>
          <KyvernoOverviewCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isHarborAvailable}>
        <Grid item sm={6} >
          <HarborWidget  />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isVaultAvailable}>
        <Grid item md={6} xs={6}>
            <EntityVaultCard  />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={e => !!isDashboardSelectorAvailable(e)}>
        <Grid item md={6}>
          <EntityGrafanaDashboardsCard  />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
    <EntitySwitch>
      <EntitySwitch.Case if={isAlertSelectorAvailable}>
        <Grid item md={6}>
          <EntityGrafanaAlertsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      {readmeContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="TechDocs" if={isTechDocsAvailable}>
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci" title="CI" if={isArgoWorkflowsAvailable}>
      {ciContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/cd" title="CD" if={isArgocdAvailable}>
      {cdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dashboard" title="Dashboard" if={isOverviewDashboardAvailable}>
      {dashboardContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/registry" title="Registry" if={isHarborAvailable}>
      {registryContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/kubernetes" title="Kubernetes"if={isKubernetesAvailable}>
      <EntityKubernetesContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/kyverno-policy-reports" title="Kyverno Policy Reports">
      <KyvernoPolicyReportsTable />
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>


  </EntityLayout>
);

const websiteEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/readme" title="README">
      {readmeContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/docs" title="TechDocs" if={isTechDocsAvailable}>
      {techdocsContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci" title="CI" if={isArgoWorkflowsAvailable}>
      {ciContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/cd" title="CD" if={isArgocdAvailable}>
      {cdContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/dashboard" title="Dashboard" if={isOverviewDashboardAvailable}>
      {dashboardContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/registry" title="Registry" if={isHarborAvailable}>
      {registryContent}
    </EntityLayout.Route>
    <EntityLayout.Route path="/kubernetes" title="Kubernetes"if={isKubernetesAvailable}>
      <EntityKubernetesContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/kyverno-policy-reports" title="Kyverno Policy Reports">
      <KyvernoPolicyReportsTable />
    </EntityLayout.Route>
    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>
  </EntityLayout>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid container item md={12}>
          <Grid item md={6}>
            <EntityProvidingComponentsCard />
          </Grid>
          <Grid item md={6}>
            <EntityConsumingComponentsCard />
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const userPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const groupPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityMembersListCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

const systemPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={4} xs={12}>
          <EntityLinksCard />
        </Grid>
        <Grid item md={8}>
          <EntityHasComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasApisCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityHasResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.TOP_BOTTOM}
        title="System Diagram"
        height={700}
        relations={[
          RELATION_PART_OF,
          RELATION_HAS_PART,
          RELATION_API_CONSUMED_BY,
          RELATION_API_PROVIDED_BY,
          RELATION_CONSUMES_API,
          RELATION_PROVIDES_API,
          RELATION_DEPENDENCY_OF,
          RELATION_DEPENDS_ON,
        ]}
        unidirectional={false}
      />
    </EntityLayout.Route>
  </EntityLayout>
);

const domainPage = (
  <EntityLayout>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayout>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
